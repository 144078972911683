var exports = {};
var matchOperatorsRe = /[|\\{}()[\]^$+*?.]/g;

exports = function (str) {
  if (typeof str !== "string") {
    throw new TypeError("Expected a string");
  }

  return str.replace(matchOperatorsRe, "\\$&");
};

export default exports;